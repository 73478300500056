<template>
  <div class="expense-list-container">
    <my-nav-bar
      title="我的开支"
      left-text="返回"
      left-arrow
    />
    <van-cell title="选择日期" :value="saveCurrentDate" class="choose-date-cell" is-link center @click="showDateChooseDialog = true" />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div class="expense-list-content">
        <van-list
          ref="list"
          v-model="loading"
          :finished="finished"
          @load="getExpenses"
        >
          <div v-for="(expense, index) in expenses" :key="index" class="expense-list-cell">
            <van-cell title="开支类型" :value="expense.type | typeFilter" />
            <van-cell title="支付方式" :value="expense.pay_mode | payModeFilter" />
            <van-cell title="开支人" :value="expense.spender && expense.spender.name" />
            <van-cell title="录入时间" :value="expense.spender && expense.created_at" />
            <van-cell title="开支日期" :value="expense.spender && expense.bill_date" />
            <van-cell class="expense-list-cell__amount" title="开支金额" :value="'￥'+expense.amount" />
            <van-cell title="开支内容" :label="expense.description" />
            <van-cell>
              <van-grid class="expense-list-cell__image" :column-num="3" square :border="false" :gutter="10">
                <van-grid-item v-for="(image, i) in expense.images" :key="i">
                  <van-image width="2rem" height="2.1rem" :src="image" fit="cover" />
                </van-grid-item>
              </van-grid>
            </van-cell>
            <van-button v-if="!expense.bill_id && expense.diff_date < 1" type="danger" text="撤销" square block @click="handleDelete(expense, index)" />
          </div>
        </van-list>
      </div>
    </div>
    <van-popup v-model="showDateChooseDialog" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="handleConfirmChoose"
        @cancel="showDateChooseDialog = false"
      />
    </van-popup>
  </div>
</template>

<script>
import dialyExpenseApi from '@/api/dialy_expense'
import myNavBar from '../../components/my-nav-bar.vue'
import { toDateString } from '@/utils'
const typeMaps = {
  2: '买菜',
  3: '加油、车费用',
  4: '发工资',
  5: '运费',
  9: '其它'
}
const payModeMaps = {
  1: '现金',
  2: '微信',
  3: '转账'
}
export default {
  name: 'ExpenseList',
  components: { myNavBar },
  filters: {
    typeFilter(type) {
      return typeMaps[type]
    },
    payModeFilter(payMode) {
      return payModeMaps[payMode]
    }
  },
  data() {
    return {
      expenses: [],
      page: 0,
      limit: 10,
      loading: false,
      finished: false,
      showEmpty: false,
      showDateChooseDialog: false,
      currentDate: new Date(),
      saveCurrentDate: '',
      storeId: this.$route.query.store_id
    }
  },
  computed: {
    calcDate() {
      return (expense) => {
        return (new Date() - new Date(expense.created_at)) < 86400000
      }
    }
  },
  activated() {
    window.pageYOffset = this.$route.meta.scrollTop
    document.documentElement.scrollTop = this.$route.meta.scrollTop
    document.body.scrollTop = this.$route.meta.scrollTop
  },
  methods: {
    init() {
      this.loading = false
      this.finished = false
      this.page = 0
      this.expenses = []
      this.$refs.list.check()
    },
    getExpenses() {
      this.page++
      const params = {
        page: this.page,
        limit: this.limit,
        date: this.saveCurrentDate,
        store_id: this.storeId
      }
      this.beginLoad()
      dialyExpenseApi.list(params).then(res => {
        this.expenses = this.expenses.concat(res.data.list)
        this.loading = false
        if (res.data.list.length < this.limit) { this.finished = true }
        this.showEmpty = this.expenses.length === 0
        this.endLoad()
      })
    },
    handleDelete(expense, index) {
      this.confirm('确定要撤销此次费用报销吗？').then(() => {
        const data = {
          expense_id: expense.id
        }
        dialyExpenseApi.delete(data).then(res => {
          this.success(res.msg)
          this.expenses.splice(index, 1)
        })
      })
    },
    handleConfirmChoose(value) {
      this.saveCurrentDate = toDateString(value)
      this.currentDate = value
      this.showDateChooseDialog = false
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
  .expense-list-container{
    .choose-date-cell{
      position: fixed;
      z-index: 999;
      left: 0;
      right: 0;
      top: 46px;
    }
  }
  .expense-list-content {
    margin-top: 82px;
    padding: 15px 15px 5px 15px;
  }
  .expense-list-cell {
    margin-bottom: 10px;
    &__amount {
      .van-cell {
        &__value {
          color: #ee0a24;
        }
      }
    }
    &__image {
      .van-grid-item {
        &__content {
          background-color: #F7F7F7;
          border-radius: 4px;
        }
      }
    }
  }
</style>
